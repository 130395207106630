<template>
  <div>
    <div class="cui__utils__heading">
      <strong>Профиль</strong>
    </div>
    <div class="card">
      <div class="card-header card-header-flex">
        <div class="d-flex flex-column justify-content-center mr-auto">
          <h5 class="mb-0">Редактирование профиля</h5>
        </div>
      </div>
      <div class="card-body">
        <a-form-model v-if="user" :model="user">
          <a-form-model-item class="text_area" label="Фото">
            <a-upload
              list-type="picture-card"
              :file-list="fileListMain"
              @change="handleChange"
            >
              <div v-if="fileListMain.length < 1">
                <a-icon type="plus" />
                <div class="ant-upload-text">
                  Upload
                </div>
              </div>
            </a-upload>
          </a-form-model-item>
          <a-form-model-item label="Имя" required>
            <a-input  v-model="user.first_name" />
          </a-form-model-item>
          <a-form-model-item label="Фамилия" required>
            <a-input  v-model="user.last_name" />
          </a-form-model-item>
          <a-form-model-item label="Отчество">
            <a-input  v-model="user.middle_name" />
          </a-form-model-item>
          <a-form-model-item label="Телефон" required>
            <a-input  v-model="user.phone" />
          </a-form-model-item>
          <a-form-model-item label="Email" required>
            <a-input  v-model="user.email" />
          </a-form-model-item>
          <a-button class="mb-4 mt-2 d-block" type="primary" @click="isPasswordSettingsOpen = !isPasswordSettingsOpen">
            {{ !isPasswordSettingsOpen ? 'Изменить пароль' : 'Скрыть и отменить изменения пароля' }}
          </a-button>
          <div v-if="isPasswordSettingsOpen">
            <a-form-model-item label="Старый пароль">
              <a-input  v-model="user.password_old" />
            </a-form-model-item>
            <a-form-model-item label="Новый пароль">
              <a-input  v-model="user.password" />
            </a-form-model-item>
            <a-form-model-item label="Подтверждение пароля">
              <a-input  v-model="user.password_confirmation" />
              <span v-if="isPasswordNotConfirmed" class="error_message">Пароли не совпадают</span>
            </a-form-model-item>
          </div>
        </a-form-model>
        <a-button class="ml-auto d-block" type="primary" @click="submit" :disabled="isDisabled">
          Сохранить изменения
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'profile',

  data() {
    return {
      isPasswordSettingsOpen: false,
      fileListMain: [],
      user: {},
    }
  },

  created() {
    this.getUserInfo()
  },

  watch: {
    isPasswordSettingsOpen() {
      if (!this.isPasswordSettingsOpen) {
        this.removePasswordsValues()
      }
    },
  },

  computed: {
    isDisabled() {
      let isDisabled = false
      const { user } = this

      if (!(user.first_name && user.last_name && user.phone && user.email)) isDisabled = true

      if (this.isPasswordSettingsOpen) {
        if (!user.password_old || !user.password || !user.password_confirmation) isDisabled = true

        if (this.isPasswordNotConfirmed) isDisabled = true
      }

      return isDisabled
    },
    isPasswordNotConfirmed() {
      const user = this.user
      return user.password && user.password_confirmation && user.password !== user.password_confirmation
    },
  },

  methods: {
    ...mapActions({ setUserInfo: 'user/LOAD_CURRENT_ACCOUNT' }),

    removePasswordsValues() {
      if (this.user.password_old !== undefined) delete this.user.password_old

      if (this.user.password !== undefined) delete this.user.password

      if (this.user.password_confirmation !== undefined) delete this.user.password_confirmation
    },

    async getUserInfo() {
      this.user = (await this.$services.get('/admin/my_profile')).data.data.value

      if (this.user.image) {
        this.fileListMain.push({
          url: this.user.image,
          uid: '-4',
          name: 'image.png',
          status: 'done',
        })
      }
    },

    handleChange({ fileList }) {
      this.fileListMain = fileList
    },

    async submit() {
      if (this.fileListMain.length && this.fileListMain[0].thumbUrl) {
        this.user.icon = this.fileListMain[0].thumbUrl
        this.user.image = this.fileListMain[0].thumbUrl
      }

      if (this.user.image && !this.fileListMain.length) {
        this.user.icon = ''
        this.user.image = ''
      }

      try {
        const res = (await this.$services.put('admin/my_profile', this.user)).data.data.value

        this.setUserInfo(this.user)
        this.user = res
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.ant-form {
  ::v-deep .ant-form-item-label {
    height: fit-content;
    line-height: 120%;
    label {
      height: fit-content;
    }
  }
  .ant-input-disabled, .ant-select-disabled {
    background-color: #ffffff;
    color: #595c97;
    ::v-deep .ant-select-selection {
      background-color: #ffffff;
    }
  }
  ::v-deep .ant-form-item-control {
    line-height: 30px;
  }
  .error_message {
    font-size: 12px;
    color: #fa0303;
  }
}
</style>
